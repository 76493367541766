var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import PageHeading from "../../components/pageheading/pageheading";
import { useEffect, useState } from "react";
import { blueBtn, pageWrapper, redBtn } from "../../utils/classes";
import Avatar from "../../components/avatar/avatar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getUser, updateUser } from "../../utils/http";
import { formatedDate } from "../../utils/fn";
export default function UserProfile() {
    var userid = useParams().userid;
    var _a = useState(), user = _a[0], setUser = _a[1];
    var _b = useQuery({
        queryKey: ["user", userid],
        queryFn: function (_a) {
            var signal = _a.signal;
            return getUser({ signal: signal, userid: userid });
        },
        staleTime: 360000
    }), queryData = _b.data, isQueryPending = _b.isPending, isQueryError = _b.isError, queryError = _b.error;
    useEffect(function () {
        if (queryData) {
            setUser(function () { return (queryData); });
        }
    }, [queryData]);
    var _c = useMutation({
        mutationFn: updateUser,
        onSuccess: function (data, variables) {
            var updatedUser = __assign(__assign({}, user), { userType: variables.data.userType });
            setUser(function (previousState) {
                if (!previousState) {
                    return previousState; // Return undefined if previousState is undefined
                }
                return __assign(__assign({}, previousState), updatedUser);
            });
        }
    }), mutate = _c.mutate, isMutationPending = _c.isPending, isMutationError = _c.isError, mutationError = _c.error;
    function makeadmin() {
        return __awaiter(this, void 0, void 0, function () {
            var userType;
            return __generator(this, function (_a) {
                userType = (user === null || user === void 0 ? void 0 : user.userType) === "0" ? "1" : "0";
                mutate({
                    data: { userType: userType, userid: userid },
                    context: { userType: userType }
                });
                return [2 /*return*/];
            });
        });
    }
    // async function makeadmin() {
    //   try {
    //     await httpPost("/user/update", {
    //       userid: profileId,
    //       userType: user?.userType === "0" ? "1" : "0"
    //     })
    //     let localuser: IUser = { ...user } as IUser;
    //     let updatedUser = { ...localuser, userType: user?.userType === "0" ? "1" : "0" };
    //     setUser((previousState) => ({...previousState, ...updatedUser }));
    //   } catch (error) {
    //     // Handle error if needed
    //     // setError(error.message);
    //   } finally {
    //     // setLoading(false);
    //   }
    // }
    return _jsxs(_Fragment, { children: [_jsx(PageHeading, { title: "".concat(user === null || user === void 0 ? void 0 : user.firstName, " ").concat(user === null || user === void 0 ? void 0 : user.lastName) }), _jsx("div", __assign({ className: pageWrapper }, { children: _jsxs("div", __assign({ className: "grid grid-cols-1 sm:grid-cols-2     dark:border-gray-700 justify-between mt-5" }, { children: [_jsx("div", __assign({ className: "w-full max-w-lg bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700" }, { children: _jsxs("div", __assign({ className: "flex flex-col items-center py-10" }, { children: [_jsx(Avatar, { path: "", size: "20" }), _jsx("h5", __assign({ className: "mb-1 text-xl font-medium text-gray-900 dark:text-white" }, { children: "".concat(user === null || user === void 0 ? void 0 : user.firstName, " ").concat(user === null || user === void 0 ? void 0 : user.lastName) })), _jsxs("h6", __assign({ className: "text-sm text-gray-500 dark:text-gray-400 border-b py-5 mb-5" }, { children: ["Member Since: ", formatedDate(user === null || user === void 0 ? void 0 : user.createdAt)] })), _jsx("span", __assign({ className: "text-sm text-gray-500 dark:text-gray-400" }, { children: user === null || user === void 0 ? void 0 : user.email })), _jsxs("div", __assign({ className: "flex mt-4 md:mt-6" }, { children: [_jsx("button", __assign({ className: blueBtn }, { children: "Send Message" })), _jsx("button", __assign({ onClick: function () { return makeadmin(); }, className: "".concat(redBtn, " ml-2") }, { children: (user === null || user === void 0 ? void 0 : user.userType) === "0" ? "Make admin" : "Remove Admin" }))] }))] })) })), _jsx("div", __assign({ className: "w-full max-w-xl" }, { children: _jsx("h1", __assign({ className: "mb-1 text-xl font-medium text-gray-900" }, { children: "Subscription History" })) }))] })) }))] });
}
