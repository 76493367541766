"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IRecommondationStatus = exports.Months = exports.IRecommondationFor = exports.IRecommondationType = exports.IUserType = void 0;
const months_1 = require("./src/months");
Object.defineProperty(exports, "Months", { enumerable: true, get: function () { return months_1.Months; } });
const recommondation_1 = require("./src/recommondation");
Object.defineProperty(exports, "IRecommondationStatus", { enumerable: true, get: function () { return recommondation_1.IRecommondationStatus; } });
Object.defineProperty(exports, "IRecommondationType", { enumerable: true, get: function () { return recommondation_1.IRecommondationType; } });
Object.defineProperty(exports, "IRecommondationFor", { enumerable: true, get: function () { return recommondation_1.IRecommondationFor; } });
const user_1 = require("./src/user");
Object.defineProperty(exports, "IUserType", { enumerable: true, get: function () { return user_1.IUserType; } });
