export var IRecommondationStatus;
(function (IRecommondationStatus) {
    IRecommondationStatus[IRecommondationStatus["Open"] = 0] = "Open";
    IRecommondationStatus[IRecommondationStatus["Target Achieved"] = 1] = "Target Achieved";
    IRecommondationStatus[IRecommondationStatus["Stoploss Hit"] = 2] = "Stoploss Hit";
    IRecommondationStatus[IRecommondationStatus["Exited"] = 3] = "Exited";
})(IRecommondationStatus || (IRecommondationStatus = {}));
export var IRecommondationFor;
(function (IRecommondationFor) {
    IRecommondationFor[IRecommondationFor["Free"] = 0] = "Free";
    IRecommondationFor[IRecommondationFor["Paid"] = 1] = "Paid";
})(IRecommondationFor || (IRecommondationFor = {}));
export var IRecommondationType;
(function (IRecommondationType) {
    IRecommondationType[IRecommondationType["Short Term (Equity)"] = 0] = "Short Term (Equity)";
    IRecommondationType[IRecommondationType["Long Term (Equity)"] = 1] = "Long Term (Equity)";
    IRecommondationType[IRecommondationType["Future"] = 2] = "Future";
    IRecommondationType[IRecommondationType["Options"] = 3] = "Options";
    IRecommondationType[IRecommondationType["Commodity"] = 4] = "Commodity";
    IRecommondationType[IRecommondationType["Currency"] = 5] = "Currency";
})(IRecommondationType || (IRecommondationType = {}));
