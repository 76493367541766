var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { pageWrapper, purpleBtn } from "../../utils/classes";
import { tokenLoader } from "../../utils/auth";
export function Login() {
    var navigate = useNavigate();
    useEffect(function () {
        var token = tokenLoader();
        console.log(token);
        if (token) {
            navigate("/dashboard");
        }
    }, [tokenLoader]);
    return _jsx(_Fragment, { children: _jsx("div", __assign({ className: pageWrapper }, { children: _jsx("form", __assign({ className: "max-w-sm mx-auto mt-100 pt-10 flex justify-center min-h-full" }, { children: _jsx(Link, __assign({ to: "".concat(process.env.REACT_APP_APIURL, "/login-with-google/web"), className: purpleBtn }, { children: "Login with google" })) })) })) });
}
