var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form } from "react-router-dom";
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import Avatar from "../avatar/avatar";
export function UserNavigation() {
    return _jsx(_Fragment, { children: _jsxs(Menu, __assign({ as: "div", className: "relative ml-3" }, { children: [_jsx("div", { children: _jsxs(Menu.Button, __assign({ className: "relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" }, { children: [_jsx("span", { className: "absolute -inset-1.5" }), _jsx("span", __assign({ className: "sr-only" }, { children: "Open user menu" })), _jsx(Avatar, { path: "", size: "10" })] })) }), _jsx(Transition, __assign({ as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-75", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95" }, { children: _jsx(Menu.Items, __assign({ className: "absolute left-0 sm:left-auto sm:right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" }, { children: _jsx(Menu.Item, { children: _jsx(Form, __assign({ action: "/logout", method: 'post' }, { children: _jsx("button", __assign({ className: 'block px-4 py-2 text-sm text-gray-700 w-100 text-left' }, { children: "Logout" })) })) }) })) }))] })) });
}
