var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export default function PageHeading(props) {
    return _jsx("div", __assign({ className: "min-h-full" }, { children: _jsxs("header", __assign({ className: "bg-white shadow" }, { children: [".", _jsx("div", __assign({ className: "mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8" }, { children: _jsx("h1", __assign({ className: "text-3xl font-bold tracking-tight text-gray-900" }, { children: props.title })) }))] })) }));
}
