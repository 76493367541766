import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../header/header";
import { useEffect } from "react";
import { setToken, tokenLoader } from "../../utils/auth";
export function Root() {
    var searchParams = useSearchParams()[0];
    var navigate = useNavigate();
    useEffect(function () {
        if (searchParams.get('token') || tokenLoader()) {
            if (searchParams.get('token')) {
                setToken(JSON.stringify(searchParams.get('token')));
            }
            navigate("/dashboard");
        }
        else {
            navigate("/login");
        }
    }, []);
    return _jsxs(_Fragment, { children: [_jsx(Header, {}), _jsx(Outlet, {})] });
}
