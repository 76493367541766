import { redirect } from "react-router-dom";
export function setToken(token) {
    localStorage.setItem('token', token);
}
export function getAuthTokan() {
    var token = localStorage.getItem("token");
    return JSON.parse(token);
}
export function tokenLoader() {
    return getAuthTokan();
}
export function checkAuthLoader() {
    var token = getAuthTokan();
    if (!token) {
        return redirect("/login");
    }
    return token;
}
