var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
export default function Avatar(props) {
    return _jsx(_Fragment, { children: !props.path ? _jsx("span", __assign({ className: "w-".concat(props.size, " h-").concat(props.size, " block relative overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600") }, { children: _jsx("svg", __assign({ className: "w-".concat(props.size, " h-").concat(props.size, " absolute text-gray-400"), fill: "currentColor", viewBox: "0 0 20 20", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", d: "M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z", clipRule: "evenodd" }) })) })) :
            _jsx("img", { className: "w-".concat(props.size, " h-").concat(props.size, " p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"), src: props.path, alt: props.path }) });
}
