var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { StrictMode } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './App.css';
import Dashboard from './pages/dashboard/dashboard';
import CreateRecommondation from './pages/recommondation/createrecommondation';
import { Root } from './components/root/root';
import ErrorPage from './pages/errorpage/Errorpage';
import Users from './pages/users/users';
import { Login } from './pages/auth/login';
import UserProfile from './pages/users/userprofile';
import Recommondations from './pages/recommondation/recommondations';
import Reports from './pages/reports/reports';
import { action as logoutAction } from "./pages/auth/logout";
import { checkAuthLoader, tokenLoader } from './utils/auth';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './utils/http';
var router = createBrowserRouter([
    {
        path: '/',
        element: _jsx(Root, {}),
        errorElement: _jsx(ErrorPage, {}),
        id: "root",
        loader: tokenLoader,
        children: [
            { path: '/', element: _jsx(Login, {}) },
            { path: '/dashboard', element: _jsx(Dashboard, {}), loader: checkAuthLoader },
            { path: '/create-recommondation', element: _jsx(CreateRecommondation, {}), loader: checkAuthLoader },
            { path: '/recommondations', element: _jsx(Recommondations, {}), loader: checkAuthLoader },
            { path: '/users', element: _jsx(Users, {}), loader: checkAuthLoader },
            { path: '/user/:userid', element: _jsx(UserProfile, {}), loader: checkAuthLoader },
            { path: '/reports', element: _jsx(Reports, {}), loader: checkAuthLoader },
            { path: '/login', element: _jsx(Login, {}) },
            { path: '/logout', action: logoutAction },
        ]
    },
]);
function App() {
    return (_jsx("div", __assign({ className: "App" }, { children: _jsx(StrictMode, { children: _jsx(QueryClientProvider, __assign({ client: queryClient }, { children: _jsx(RouterProvider, { router: router }) })) }) })));
}
export default App;
